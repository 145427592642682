
import logo from './assets/img/logo.svg';
import IntroText from './IntroText';
import ReactPlayer from 'react-player';
import { useState, useEffect, useRef } from 'react';
import Questions from './Questions';
import EndTitle from './Endtitle';
import config from './config';
import { AnimatePresence } from 'framer-motion';
// import plate from './assets/img/rsz_plate.png';
// import pattern from './assets/img/pettern.svg';
import { motion } from 'framer-motion';
import sound from '../src/assets/img/sound-on.svg';
import skip from '../src/assets/img/skip.svg';
// import curl from 'curl';

function App() {
  const [start, setStart] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [Data, setData] = useState({});
  let [currentQuestion, setCurrentQuestion] = useState(1);
  let [videoLoaded, setVideoLoaded] = useState(false);
  let [answerArray, setAnswerArray] = useState([]);
  let [endReached, setEndReached] = useState(false);
  let lengthOfQuestionaire = Object.keys(Data).length;
  let [progressLength, setProgressLength] = useState(0);
  let [stopTimeStamp, setStopTimeStamp] = useState(0);
  let [negativeTimeStamp, setNegativeTimeStamp] = useState(0)
  let [showQuestionTimeStamp, setShowQuestionTimeStamp] = useState(0);
  let [questionsVisible, setQuestionsVisible] = useState(false);
  let [chosenPath, setChosenPath] = useState('positive');
  const videoRef = useRef(null);
  let bedanktTimeStamp = '1047.453000';
  let returnTimeStamp = '173.554000';

  useEffect(() => {
    fetch(config.SERVER_URL + "/question", {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    },
    ).then(response => {
      if (response.ok) {
        response.json().then(json => {
          setData(json);
        });
      }
    });
  }, []);

  useEffect(() => {

    if(endReached) {
      // let answers = JSON.stringify(answerArray);
      sendDataAfterComplete(answerArray);
    }


  }, [answerArray, endReached]);

  if (!Data) return "";

  return (
      <div className="app">
        <nav className="nav">
          <div className="container">
              <div className="row">
                  <div className="nav__inner">
                      <img className="logo" src={logo} alt=""/>
                      {start ? 
                              <motion.div initial={{opacity: 0}} animate={{opacity: 1}} className="progress desktop">
                                <div className="progress__bar" style={{width : `${progressLength}%`}}></div>
                              </motion.div>
                            : ''}
                  </div>
              </div>
          </div>
        </nav>
        <main className="main">
            <div className="container">
                <div className="row">
                    <div className="main__inner">
                        <div className="left">
                            <div className="left__inner">
                                
                                <ReactPlayer playsinline onReady={() => handleVideoLoaded()} ref={videoRef} playbackRate={1} onProgress={(progress) => handleVideoProcess(progress)} progressInterval={100} controls={false} playing={playing ? true : false} width="100%" height="100%" url={config.UITGEKOOKT_VIDEO_URL} />
                                {/* <AnimatePresence>
                                  {playing && start && !questionsVisible ? 
                                    <motion.div initial={{x: 0, opacity: 0, y: "-50%"}} animate={{x: "300px", opacity: 1, y: "-50%", transition: {duration: .6, ease: "easeInOut"}}} exit={{x: 0, opacity: 0, y: "-50%", transition: {duration: .5, ease: "easeInOut"}}} className="plate-container desktop">
                                        <motion.img initial={{rotate: "-30deg"}} animate={{rotate: 0, transition: {duration: 1, ease: "easeInOut"}}} className={'plate-container__plate'} src={plate} alt="" />
                                        <motion.img initial={{y: 50,  x: -50,}} animate={{y: 0, x: 0, transition: {delay: .3, duration: .4, ease: "easeInOut"}}} className={'plate-container__pattern'} src={pattern} alt="" />
                                    </motion.div>
                                  : ''}
                                </AnimatePresence> */}
                                <AnimatePresence>
                                {videoLoaded && !start ? 
                                      <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: .3}} className="left__inner__center">
                                        <div onClick={() => handleStart()} className={`playbutton ${ start ? "remove" : ""}`}>
                                          <span>Start</span>
                                        </div>
                                        <div className="sound">
                                            Zet je geluid aan 
                                            <img src={sound} alt="" />
                                        </div>
                                      </motion.div>
                                : ''}
                                 </AnimatePresence>
                            </div>
                        </div>
                        <div className="right">
                             {start ? 
                              (
                                <>
                                  <motion.div className="progress mobile" initial={{opacity: 0}} animate={{opacity: 1}}>
                                    <div className="progress__bar" style={{width : `${progressLength}%`}}></div>
                                  </motion.div>
                                  <AnimatePresence>
                                      {start && !questionsVisible ? (
                                        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10, transition: {duration: .1} }} onClick={handleSkipVideo} className="skip-button">Video overslaan <img src={skip} alt="" /> </motion.div>
                                      ) : '' }
                                  </AnimatePresence>
                                </>

                              )
                              : ''}
                            {!endReached ? 
                              <>
                                {!start && videoLoaded ? 
                                <AnimatePresence><IntroText lengthOfQuestionaire={lengthOfQuestionaire} start={handleStart}/></AnimatePresence> : (
                                      <AnimatePresence>
                                        {questionsVisible ?
                                            <Questions questionData={Data} currentQuestion={currentQuestion} handleNextQuestion={handleNextQuestion} />
                                        : ''}
                                      </AnimatePresence>
                                  ) 
                                }
                              </> 
                            : 
                              <>
                                {endReached ? <AnimatePresence><EndTitle path={chosenPath} /></AnimatePresence> : ''}
                              </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <footer>
              <div className="container">
                  <div className="row">
                      <ul>
                          <li><a target="_blank" href="https://uitgekookt.nl/algemene-voorwaarden">Algemene voorwaarden</a></li>
                          <li><a target="_blank" href="https://uitgekookt.nl/privacy-verklaring">Privacy policy</a></li>
                      </ul>
                  </div>
              </div>
        </footer>
      </div>
  );

  function handleSkipVideo() {
    videoRef.current.seekTo(showQuestionTimeStamp, 'seconds');

  }


  // If player is loaded, start the whole shabang
  function handleVideoLoaded() {
    setVideoLoaded(true);
  }

  // Function that handles the process of the video
  function handleVideoProcess(progress) {
    let played = progress.playedSeconds;
    // show questions on timestamp
    if(played > showQuestionTimeStamp) {
        setQuestionsVisible(true);
    }

    // If end of answer time is reached
    if(played >= stopTimeStamp ) {
      setPlaying(false);
    }
    
  } 
  
  // Function that handles the start and resets the given answer array.
  function handleStart() {
    setStart(true);
    setAnswerArray([]);
    setPlaying(true);
    setStopTimeStamp(Data[currentQuestion].stopPlayingOn);
    setShowQuestionTimeStamp(Data[currentQuestion].showQuestions);
    setNegativeTimeStamp(Data[lengthOfQuestionaire].whenQuestionStarts);
    

    // get Todays date.
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    today = yyyy + "-" + mm + "-" + dd;

    // Get SHA user data and adds it to answerArray
    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let urlParam = urlParams.get('sha');

    // Replaces spaces with plusses.
    var str = urlParam.replace(/\s+/g, '+').toLowerCase();

    let userData = {
      encryptedUserEmail: str,
      date: today
    }

    setAnswerArray([...answerArray, userData]);
  }

  function getQueryParams(qs) {
    // do not split the + characters to spaces
    // qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

  // function that pushed given answer to array and handles the update to the next one
  function handleNextQuestion(answer, question, path, questionNumber) {

    let newAnswer = {
   
      question: question,
      answer: answer
    }
    
    setAnswerArray([...answerArray, newAnswer]);

    if(path === 'positive') {

      // check if last question is reached and if positive path
      if(currentQuestion < lengthOfQuestionaire) {
        // check if last question is reached and skips
        if(currentQuestion === lengthOfQuestionaire - 1) {
          // Skips last open question on positive path when last question is reached, this because this question is negative path
          setEndReached(true);
            // set video to timeframe with 'bedankt'.
          videoRef.current.seekTo(bedanktTimeStamp, 'seconds');
          setStopTimeStamp(videoRef.current.getDuration());
          setShowQuestionTimeStamp('');
          setPlaying(true);

        } else {
          setCurrentQuestion(currentQuestion + 1);

          // If answer and continue is clicked, set video to start of next video part
          videoRef.current.seekTo(stopTimeStamp, 'seconds')

          // Sets the next timestamps: stop playing on, show questions
          setStopTimeStamp(Data[currentQuestion + 1].stopPlayingOn);
          setShowQuestionTimeStamp(Data[currentQuestion + 1].showQuestions);
        }
      } else {
        setEndReached(true);  
        setStopTimeStamp(videoRef.current.getDuration());
        setShowQuestionTimeStamp('');
      } 
    }

    if(path === 'negative') {
      setChosenPath('negative')
      if(currentQuestion < lengthOfQuestionaire) {
        // check if negative path and emedialely skips to last open question
        setCurrentQuestion(lengthOfQuestionaire);
        // Set video to end question
        videoRef.current.seekTo(negativeTimeStamp, 'seconds');
          // Sets the next timestamps: stop playing on, show questions
          setStopTimeStamp(Data[lengthOfQuestionaire].stopPlayingOn);
          setShowQuestionTimeStamp(Data[lengthOfQuestionaire].showQuestions);
  
      } else {
        // setEndReached(true);
        // set video to timeframe question 3 again (just a bit later on).
        videoRef.current.seekTo(returnTimeStamp, 'seconds');
        // setStopTimeStamp(videoRef.current.getDuration());
        setCurrentQuestion(3);
        setStopTimeStamp(Data[3].stopPlayingOn);
        setShowQuestionTimeStamp(Data[3].showQuestions);
        setPlaying(true);

      } 
    }

    // updates the progressbar
    handleProgressBarUpdate(path);

    // Continues video
    setPlaying(true);
    setQuestionsVisible(false);

  }

  // Function that updates the progress bar
  function handleProgressBarUpdate(path){
  
    // Check if negative question which has shorter progress steps
    if(path === 'negative') {
      if(currentQuestion === 2) {
        // if first and second question is set, set progress to 50%
        setProgressLength((100 / (lengthOfQuestionaire - 1)) * 3)
      }
    } else {
      // Sets the length of the bar in percentages
      setProgressLength((100 / (lengthOfQuestionaire - 1)) * currentQuestion)
    }
  }


  function sendDataAfterComplete(jsonData) {
    // Send entry to server, so server can write row into CSV
    fetch(config.SERVER_URL + "/submission", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData),
    },
    ).then(response => {
      if (response.ok) {
        response.json().then(json => {
        });
      }
    }).catch(error => {
    });
  }
  
}

export default App;



