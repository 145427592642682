import { motion } from "framer-motion";


const EndTitle = (props) => {

    const parentAnimation = {
        initial: {
            opacity: 0,
            y: 20
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .2,
                ease: "easeInOut"
            }
        },
        exit: {
            opacity: 0
        }
    }

    return (
        <>
            <motion.div variants={parentAnimation} initial={'initial'} animate={'animate'} exit={'exit'} className="end-title">
                <h2>Bedankt!</h2>
                <p>
                    Dankjewel dat je de tijd hebt genomen om onze vragen te beantwoorden. En zoals beloofd heb ik een verrassing voor je. 
                    Je kunt met onderstaande code 1 gratis dessert toevoegen aan je volgende bestelling. Je mag zelf kiezen welk dessert. 
                </p>
                <fieldset>
                    <legend>Vouchercode:</legend>
                    <div>
                        <h3>Ikbenuitgekookt</h3>
                    </div>
                </fieldset>
                <a className="btn" target="_blank" href="https://www.uitgekookt.nl/maaltijden">Bekijk maaltijden</a>
                <p>
                    Nogmaals bedankt dat je klant geworden bent bij Uitgekookt.nl. 
                    Daar zijn we hartstikke blij mee en we hopen dat we elke week weer iets lekkers voor je mogen koken. Ik ben al druk bezig met de nieuwe recepten en ik hoop dat je er van zal genieten. 
                </p>
                <p>
                    Graag tot snel!
                </p>
            </motion.div>
        </>
    )
}

export default EndTitle