import clock from '../src/assets/img/clock.svg';
import question from '../src/assets/img/doeg.svg';
import { motion } from 'framer-motion';
const IntroText = (props) => {

    const parentAnimation = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: {
                staggerChildren: .1
            }
        },
        exit: {
            opacity: 0
        }
    }

    const textAnimation = {
        initial: {
            y: -20,
            opacity: 0,
            transition: {
                duration: .2,
                ease: "easeInOut"
            }
        },
        animate: {
            y: 0,
            opacity: 1
        }
    }

    return (
        <motion.div variants={parentAnimation} initial={'initial'} animate={'animate'} exit={'exit'} className="introtext">
            <motion.ul variants={textAnimation} initial={'initial'} animate={'animate'} className="duration-data">
                <li><img src={question} alt="" /> {props.lengthOfQuestionaire} vragen</li>
                <li><img src={clock} alt="" /> 10 min</li>  
            </motion.ul>
            <motion.h1 variants={textAnimation} initial={'initial'} animate={'animate'} className="main-title">Uitgekookt.nl <span>Enquête</span></motion.h1>
            <motion.p variants={textAnimation} initial={'initial'} animate={'animate'}>Doe nu de enquete en ontvang een leuke verrassing. Doe je mee?</motion.p>
            <motion.p variants={textAnimation} initial={'initial'} animate={'animate'}>Klik op <span onClick={() => props.start()}>start</span> om te beginnen.</motion.p>
        </motion.div>
    )
}

export default IntroText;