import arrow from '../src/assets/img/arrow-right.svg';
import { useState } from 'react';
import {motion} from 'framer-motion'
import Pencil from '../src/assets/img/pencil.svg';

const Questions = (props) => {
    const [buttonActive, setButtonActive] = useState(false);
    let questionData = props.questionData[props.currentQuestion];


    const parentAnimation = {
        hidden: {
            opacity: 1
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .1,
                staggerChildren: 0.1,
                ease: "linear" 
            }
        }
    } 

    const itemVariants = {
        hidden: {
          opacity: 0,
          y: -20
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: .1,
            ease: "linear" 
          }
        }
      }; 

    return (
        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ease: "linear"}} className={`question-container`} >
            <motion.ul initial={"hidden"} animate={"visible"} variants={parentAnimation} className={questionData.questionType === 'radio' ? 'flex-items' : ''}>
                {questionData.answers.map((e, i) => {
                    if(e.type === 'fixed') {
                        return (
                            <motion.li variants={itemVariants} key={i} className="question-container__answer custom-checkbox" onClick={(e) => handleAnswerClick(e)} data-question={questionData.question} data-path={e.path}>
                                <div className="checkbox"></div>
                                <p className="questions-container__answer__text">{e.text}</p>
                            </motion.li>
                        );
                    }
                    if(e.type === 'free') {
                        return (
                           
                             <motion.li variants={itemVariants} key={i} className="question-container__answer custom-text" onClick={(e) => handleAnswerClick(e)} data-question={questionData.question} data-path={e.path}>
                                <div className="checkbox"></div>
                                <img className={`pencil`} src={Pencil} alt="" />
                                <textarea placeholder="Typ hier je antwoord..."/>
                            </motion.li>
                        )
                    }
                    if(e.type === 'radio') {
                        return (
                           
                             <motion.li variants={itemVariants} key={i} className="question-container__answer custom-radio" onClick={(e) => handleAnswerClick(e)} data-question={questionData.question} data-path={e.path}>
                                <div className="checkbox"></div>
                                <p>{e.text}</p>
                            </motion.li>
                        )
                    }
                    
                })}
            </motion.ul>
            <div 
                className={`button button--arrow ${buttonActive || props.currentQuestion === 1 ? "" : "disabled" }`} 
                onClick={() => handleButtonClick()}>
                    {props.currentQuestion === 1 ? 'Zeker weten' : 'Verder'}
                    
                    <img src={arrow} alt="" />
            </div>
        </motion.div>
    )
    
    // answer button click handler
    function handleAnswerClick(e) {

        // remove active class from all other answers and resets goven answer;
        const questions = document.querySelectorAll('.question-container__answer');
        questions.forEach((e) => {
            e.classList.remove('active');
        });

        // Sets active class on clicked item
        const clickedElement = e.currentTarget;
        clickedElement.classList.add('active');

        // Sets button to active
        setButtonActive(true)
    }

    // proceed button click handler
    function handleButtonClick() {
        const questions = document.querySelectorAll('.question-container__answer');
        let answer = '';
        let question  = '';
        let path = '';
        
        // Loop through questions and return given answer plus asked question
        if(questions.length > 0) {
            questions.forEach((e) => {
                if(e.classList.contains('active')) {
                    question = e.dataset.question;
                    // if path exists set path, otherwise always positive
                    path = e.dataset.path;
    
                    if(e.classList.contains('custom-text')){
                        answer = e.querySelector('textarea').value;
                    } else {
                        answer = e.querySelector('p').textContent;
                    }
                }
                e.classList.remove('active');
            });
        }

        // If its the first question, which has no answers, then set feedback manually
        if(props.currentQuestion === 1) {
            answer = 'Ik wil meedoen';
            question = 'Wil je meedoen?';
            path = 'positive'
        }

        // Button inactive and return the given feedback
        setButtonActive(false)
        props.handleNextQuestion(answer, question, path, props.currentQuestion);
    }
} 

export default Questions;