import { useState, useEffect } from 'react';
import logo from './assets/img/logo.svg';
import config from './config';

function Download() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const loginSuccess = urlParams.get("loginSuccess");
    const [state, setState] = useState({
        verifiedCode: false,
        authorised: false,
    });

    useEffect(() => {
        if (code && !state.verifiedCode) {
            fetch(config.SERVER_URL + "/verify", {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code }),
              },
              ).then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    console.log(json);
                    setState({ ...state, verifiedCode: true, authorised: json.ok });
                  });
                }
              }).catch(error => {
                setState({ ...state, verifiedCode: true, authorised: false });
              });
        }
    }, [state]);
    
    return (
        <div className="app download-wrapper">
            <main className="main">
                <div className="container">
                    <div className="row">
                        <img src={logo} alt={'uitgekookt logo'}/>
                        {state.authorised ? ( // Authorised
                            <div className="content">
                                <h2>Uitgekookt.nl Enquête</h2>
                                <h3 style={{ color: "#fff" }}>Inzendingen</h3>
                                <a download href={config.SERVER_URL + "/download" + "?code=" + code}>Download CSV</a>
                            </div>
                        ) : ( // Unauthorised
                            <div className="content">
                                <h2>Uitgekookt.nl Enquête</h2>
                                <h3 style={{ color: "#fff" }}>Log in om de inzendingen op te vragen</h3>
                                <form action={config.SERVER_URL + "/login"} method="post">
                                    <div className="input-wrapper">
                                        <input
                                            style={{

                                            }}
                                            placeholder="Vul inlogcode in"
                                            type="password"
                                            name="logincode"
                                        />
                                        {loginSuccess && loginSuccess === "false" ? (
                                            <p style={{ color: "red" }}>Inlogcode onjuist</p>
                                        ) : null}
                                        <button type="submit">Log in</button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Download